import React from "react";
import { ser8 } from "./Export";
function Consumer(){
return(
    <>
<div className="laptop mt-5 ms-5 ">
    <h1 className="cch">Consumer Services</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0" >
     <img src={ser8}className="p-4 ccimg" alt="the image is not shown"  />
     <div class="card-body" style={{color:"black"}}>
       <h6 class="card-title cct">We continuously expand strategic products in our Consumer Electronics division, Expanding market leadership in the memory business by demonstrating superior technology and dramatic cost savings. That is generating exciting prospects. We are expanding our highly profitable differentiated products like Laptops, Desktops and accelerating process conversion while also focusing on developing next-generation products.</h6>
     </div>
   </div>
   </div>

 
  </div>
    </div>
    </>
)
}
export default Consumer