import React from "react"

import {items} from "./Data"
function Interactive(){
    return(
        <>
  <div className="inter1">
    <h1 className="p-4 cch">Interactive Flat Panel</h1>
    <div className="inter2">
    {items.map((item)=>
     <div class="card card1 border-0" style={{backgroundColor:"grey"}}>
     <img src={item.imgg} className="p-4" alt="the image is not shown" style={{backgroundColor:"grey"}}/>
     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title text-center cct">{item.head}</h6>
     </div>
   </div>
    )}
   
    </div>
    
  </div>
        </>
    )

}
export default Interactive