import React from "react";
import { ser9 } from "./Export";
function Amc(){
    return(
        <>
 <div className="laptop mt-5 ms-5 ">
    <h1 className="cch">AMC & FMS Solutions</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0" >
     <img src={ser9}className="p-4 ccimg" alt="the image is not shown"  />
     <div class="card-body" style={{color:"black"}}>
       <h6 class="card-title cct">We provide AMC and FMS services to leading orgnisations so that they get round the clock IT availability and overcome IT challenges to focus on their business goals.
       We provide AMC and FMS services to Organisations in both Comprehensive and Non-Comprehensive format. Our engineers are well-equipped and trained to understand a business organisation's IT support requirement..</h6>
     </div>
   </div>
   </div>

 
  </div>
    </div>
        </>
    )
}
export default Amc