import React from "react"
import { Outlet } from "react-router"
function Services(){
    return(
        <>
        {/* <h1> this is services page</h1> */}
        <Outlet/>
        </>
    )
}
export default Services