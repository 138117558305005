import React from "react";
import { usb } from "./Data";
function Usb(){

    return(
        <>
       <div className="desk1 mt-5 ms-3">
    <h1 className="p-4">USB</h1>
    <div className="desk2 pb-5">
{usb.map((value)=>
    <div class="card card1 border-0" style={{width:"400px",height:"400px",backgroundColor:"grey"}}>
            <img src={value.usbimg} className="p-4" alt="the image is not shown" style={{backgroundColor:"grey"}} height="330px" width="100%"/>
            <div class="card-body" style={{backgroundColor:"white"}}>
              <h6 class="card-title text-center">{value.usbtit}</h6>
            </div>
          </div>

)}

    </div>
    
  </div>
        </>
    )
}
export default Usb