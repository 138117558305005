import React from "react";
import { ser2 } from "./Export";
function Dis(){
return(
    <>
<div className="laptop mt-5 ms-5 ">
    <h1 className="cch">Distribution Services</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0" >
     <img src={ser2}className="p-4 ccimg" alt="the image is not shown"  />
     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title cct">Declub Shoppy takes pride in working with its channel partners, distributes IT products and provide services with the high integrity.Quality technical team and logistics management help channel partners provide solutions to their end-customers</h6>
     </div>
   </div>
   </div>

 
  </div>
    </div>
    </>
)
}
export default Dis