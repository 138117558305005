function About(){
    return(
        <>
<div className="about">
    <div className="abou">
    {/* <div className="about1">
        <h1>About us</h1>
        <p>DeClub Shoppy Pvt. Ltd., established in 2020, is a premier distributor in the IT hardware and networking industry. Specializing in high-quality computer hardware, peripherals, and electronic products, DeClub Shoppy has rapidly grown as a trusted provider of cutting-edge technology solutions. With a focus on customer satisfaction, innovation, and technical expertise, the company serves diverse clientele across multiple regions, offering comprehensive logistics, technical support, and customized hardware solutions.</p>
        <h1>  Vision  </h1>
        <p>To be a leading provider of innovative IT hardware and networking solutions across Pan India, empowering businesses with cutting-edge technology that drives growth, efficiency, and sustainability. We aim to set the benchmark for quality, customer satisfaction, and nationwide reach in the technology distribution industry.</p>
    </div>
    <div className="about2">
        <img src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQBO7tvpf53YJaKXLUlUFH-U_aqvFsFEcm2bQNHGrkyxIaFQ6gq" height="400px" width="400px"/>
        <h1>Mission</h1>
        <p>
            Our Mission is deliver top-tier IT hardware and networking products backed by 24X7 service support,ensuring seamless intergation and uninterrupted-operations for our clients.Through exceptional custome service,technical expertise, and tailored solutions,we aim to foster innovation,maintain long-term partnerships,and consistently meet the evolving needs of business across India.
        </p>
    </div> */}
    <div className="about1">
<div className="abt1">
<h1>About us</h1>
        <p>DeClub Shoppy Pvt. Ltd., established in 2020, is a premier distributor in the IT hardware and networking industry. Specializing in high-quality computer hardware, peripherals, and electronic products, DeClub Shoppy has rapidly grown as a trusted provider of cutting-edge technology solutions. With a focus on customer satisfaction, innovation, and technical expertise, the company serves diverse clientele across multiple regions, offering comprehensive logistics, technical support, and customized hardware solutions.</p>
</div>
<div className="abt2">
<img src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQBO7tvpf53YJaKXLUlUFH-U_aqvFsFEcm2bQNHGrkyxIaFQ6gq" height="400px" width="400px"/>
</div>
</div>
<div className="about2">
    <div className="ab1">
    <h1>  Vision  </h1>
    <p>To be a leading provider of innovative IT hardware and networking solutions across Pan India, empowering businesses with cutting-edge technology that drives growth, efficiency, and sustainability. We aim to set the benchmark for quality, customer satisfaction, and nationwide reach in the technology distribution industry.</p>
    </div>
    <div className="ab2">
    <h1>Mission</h1>
        <p>
            Our Mission is deliver top-tier IT hardware and networking products backed by 24X7 service support,ensuring seamless intergation and uninterrupted-operations for our clients.Through exceptional custome service,technical expertise, and tailored solutions,we aim to foster innovation,maintain long-term partnerships,and consistently meet the evolving needs of business across India.
        </p>
    </div>

    </div>
    </div>
    
    <div className="services">
        <h1 className="text-center pt-4" style={{color:"blue"}}>Services</h1>
        <div className="service1">
            <div className="ser1">
                <p><strong>Comphrensive Product Distribution:</strong>Offering a wide range of  IT hardware peripherals devices, and networking and equipments across Pan India.</p>
            </div>
            <div className="ser1">
                <p><strong>24X7 Technical Support:</strong> Providing around-the-clock service support to ensure the continuous and efficient operation of IT systems</p>
            </div>
            <div className="ser1">
                <p><strong>Customized Hardware Solutions:</strong>Tailoring IT hardware solutions to meet specific business needs, ensuring optimal performance and intergation.</p>
            </div>
            <div className="ser1">
                <p><strong>Logistics & Warranty Services:</strong>Ensuring the timely delivery  and reliable post-sales support,including warranty and technical assistance.</p>
            </div>
            <div className="ser1">
                <p><strong>Consultation &  System Integration :</strong> Offering expert consultation for IT infrastructure setup,intergation, and optimization to enhance business productivity</p>
            </div>
            <div className="ser1">
                <p><strong>Sustainable Technology Solutions:</strong>Promoting eco-friendly and energy-efficient product and services to  support sustainable business practices.</p>
            </div>
        </div>
    </div>
</div>
        </>
    )
}
export default About