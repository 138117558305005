import React from "react";
import { ser10 } from "./Export";
function Sales(){
    return(
        <>
       <div className="laptop mt-5 ms-5 ">
    <h1 className="cch">Computer Sales & Solutions</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0" >
     <img src={ser10}className="p-4 ccimg" alt="the image is not shown"  />

     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title cct">Our on-site computer support service makes getting your computer fixed easy! No need to disconnect all those computer cables, and load it in the car. One of our friendly and qualified technicians will come right to your home or business and repair your computer on the spot. </h6>
     </div>
   </div>
   
   </div>
   <div className="in3 mt-5">
    {/* <h3> Biometric Solutions</h3> */}
    <h6 className="cct">Our remote support service can solve most computer issues quickly and easily. With no need for an on site appointment, this is the ideal solution to get you up and running quickly without interrupting your schedule.</h6>
    <h6 className="cct">
    Need to upgrade to something new? We sell quality computer equipment along with the data transfer services to make your new computer turn-key!!!
    </h6 >
   </div>
 

  </div>
    </div>
        </>
    )
}
export default Sales