import React from "react"
import { ledd } from "./Data"
function Led(){
return(
    <>
 <div className="comm">
    <h1 className="p-5 cch">LED Display</h1>
    <div className="comm1">
        <div className="comm2 m-4">
        {ledd.map((values)=>
         <div class="card card1 border-0" style={{width:"400px",height:"300px",backgroundColor:"grey"}}>
         <img src={values.ledimg} className="p-4" alt="the image is not shown" style={{backgroundColor:"grey"}} />
         <div class="card-body" style={{backgroundColor:"white"}}>
           <h6 class="card-title text-center cct">{values.title}</h6>
           
           
         </div>
       </div>
        )}
           
      
        
        </div>
    </div>
  </div>
    </>
)


}
export default Led