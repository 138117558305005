import React from "react"
// import {Tab,Televison} from "../images/Tab.png"
import {Tab,Televison,Laptop,Accesories,Speaker,Sale1,Acer,logo2,Dell,HP,Lenovo,Samsung,Vertiv,interactive1,Printe,Desktoppc} from "./Export"

import Countup from "react-countup"

function Index(){
return(
    <>
    <div className="conta">
    <div id="carouselExample" class="carousel slide">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://dienmaygiahan.com.vn/upload/images/post/2023_09_10_20_13_31-Tvi%20LG.jpg" class="d-block w-100" alt="..." height="500px"/>
    </div>
    <div class="carousel-item">
      <img src="https://images.unsplash.com/photo-1614624532983-4ce03382d63d?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" class="d-block w-100" alt="..." height="500px"/>
    </div>
    <div class="carousel-item">
      <img src="https://thuthuatcongnghe.com.vn/wp-content/uploads/2023/08/danh-gia-google-tivi-tcl-4k-50-inch-55p737-2.jpg" class="d-block w-100" alt="..." height="500px"/>
    </div>
    <div class="carousel-item">
      <img src={Desktoppc} class="d-block w-100" alt="..." height="500px"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div className="icon">

    <div className="icon1">
   <img src={Laptop} height="80px" width="80px" alt=""/>
   <h5 class="text-light"> Laptop</h5>
    </div>
    <div className="icon1">
   <img src={Televison} height="80px" width="80px" alt=""/>
   <h5 class="text-light">Televison</h5>
    </div>
    <div class="icon1">
   <img src={Tab} height="50px" width="40px" alt=""/>
   <h5 class="text-light pt-4">Tab</h5>
    </div>
    <div class="icon1">
      <img src={Accesories} height="80px" width="80px" alt=""/>
      <h5 class="text-light">Accesories</h5>
    </div>
    <div class="icon1">
      <img src=  {Speaker}height="80px" width="80px" alt=""/>
      <h5 class="text-light">Speaker</h5>
    </div>
    <div class="icon1">
 <img src={Printe} height="50px" width="80px" alt="" />
 {/* <i class="fa-solid fa-print" style={{color:"white",fontSize:"40px"}}></i> */}
 <h5 class="text-light"> Printer</h5>
    </div>
    {/* <div className="icon1">
    <img src={Pills} height="50px" width="80px" alt=""/>
    </div> */}
    <div class="icon1">
      <img src={interactive1}  height="100px" width="120px"alt=""/>
      <h5 class="text-light"> Interactive Panel</h5>

    </div>
</div>
<div className="top">
  <h3 class="p-4 text-center">Top Brands</h3>
  <div class="top1">
    <img src={logo2} alt="" width="250px"/>
    <img src={Dell} height="100px" alt="" />
    <img  className=" pt-4"src={Acer} alt="" height="100px" width="130px"/>
    <img src={HP} height="100px" alt=""/>
    <img src={Lenovo} height="100px" alt=""/>
    <img src={Samsung}height="100px" alt=""/>
    <img src={Vertiv}alt="" height="100px" width="150px" />

  </div>
</div>
<div class="budget ">
  <h3 className="p-4 ps-5 ">Products in Your Budget</h3>
  <div class="budget1">
<div className="under bg-dark text-light">
  <h4>Under 20K</h4>
  <p><Countup start={0} end={40} duration={100} />+ Products</p>

</div>
<div className="under1 bg-danger text-light">
  <h4>UNDER 30K</h4>
  <p><Countup start={0} end={50} duration={100} />+ Products</p>
</div>
<div className=" under2 bg-primary text-light">
  <h4>Under 40k</h4>
  <p><Countup  start={0} end={100} duration={100}/>+ Products</p>
</div>
<div className="under3 bg-secondary text-light">
  <h4>Desktop Starting 5k</h4>
  <p><Countup start={0} end={100} duration={100}/>+Products</p>
</div>
  </div>
</div>
<div className="sale">
  <div className="sale1">
  <div className="salee">
      <h5> 50% OFF</h5>
    <h5>DISCOUNT</h5>
    </div>
   <div className="sale2">
  
   <img src={Sale1} alt=""/>
   </div>
   <div className="sale3">
   <h1 className=" "> BUY LAPTOP at Lowest Price</h1>
   <button class="btn btn-primary mt-4">Shop Now</button>
   </div>
   
  </div>
</div>
<div className="featured  text-center">
  <h3>Featured Products</h3>
  <div className="feat1">
<div className="feat2 feat6">
  <img src="https://www.electronicsbazaar.com/media/catalog/product/cache/42cb70b607a6ad026bed848f494879b1/q/c/qcnbag03872_1.webp" className="pt-4" height="200px" width="270px" alt=""/>
 <h5 className="p-4" >Laptop</h5>
</div>
<div className="feat3 feat6">
<img src="https://5.imimg.com/data5/SELLER/Default/2023/3/296444519/GC/NT/UC/157472566/interactive-flat-panel-for-education.PNG" height="200px"alt="" width="270px"/>
  <h5 className="p-4">Interactive Panel</h5>
 
</div>
<div className="feat4 feat6">
<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTiA_bqbtbijUOy2giF0qfGuq5RAX1LUlrXQ&s" alt="" height="200px" width="270px"/>
  <h6 className="p-4">Commercial TV</h6>
</div>
<div className="feat5 feat6">
<img src="https://www.lifewire.com/thmb/WZdlwJIGSRA9Bkk7tKIj9Mgg-lk=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/xxl-desktop-pc-98013994-5c4dcc47c9e77c0001380389.jpg" alt="" height="200px" width="270px"/>
 <h5 className="p-4"> Desktop</h5>
</div>
  </div>
</div>
<div className="car mt-5">
<div id="carouselExampleRide" class="carousel slide car" data-bs-ride="true">
  <div class="carousel-inner">
    <div class="carousel-item active carous1">
     
  <div className="contimg">
    <img src="https://www.globalrootsindia.com/images/test-bg-70.jpg" height="400px" width="100%" alt=""/>
  
     <div className="content">
      <h3 className="mb-5">Client Testimonials</h3>
      <i class="fa-solid fa-quote-right" style={{color:"white",fontSize:"30px"}}></i>
 <p>"Thanks to Declub Shoppy, our sales process is now seamless and efficient. Their user-friendly interface and dedicated service have truly set us apart in the market."</p>
 <h4>Ankita Bhatia</h4>
     </div>
     </div>
    </div>
    <div class="carousel-item carous1">
    <div className="contimg">
    <img src="https://www.globalrootsindia.com/images/test-bg-70.jpg"  width="100%" alt=""/>
  
     <div className="content">
      <h3 className="mb-5">Client Testimonials</h3>
      <i class="fa-solid fa-quote-right" style={{color:"white",fontSize:"30px"}}></i>
      <p>"This platform has completely streamlined our online store. The user-friendly interface and excellent customer service have made managing sales effortless and efficient. We’ve never been more satisfied!"</p>
      <h4>Sanjay Bhardwaj</h4>
     </div>
     </div>
    </div>
    <div class="carousel-item carous1">
    <div className="contimg">
    <img src="https://www.globalrootsindia.com/images/test-bg-70.jpg"  width="100%" alt=""/>
  
     <div className="content">
      <h3 className="mb-5">Client Testimonials</h3>
      <i class="fa-solid fa-quote-right" style={{color:"white",fontSize:"30px"}}></i>
      <p>Exploring creative possibilities with modern web technologies, developers can craft immersive and interactive user experiences. From dynamic animations to engaging visuals, these tools enhance web design and functionality.</p>
      <h4>Karan Rathore</h4>
     </div>
     </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
    </div>
    </>
)
}
export default Index