import React from "react";
import { Outlet } from "react-router";
import Laptop from "./Laptop";
function Product(){
return(
    <>

    {/* <h1>this is product page</h1> */}
    <Outlet/>

    {/* <Laptop/> */}
    </>
)
}
export default Product