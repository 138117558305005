import React from "react";
import { ssd } from "./Data";
function Sata(){

    return(
        <>
 <div className="desk1 mt-5 ms-3">
    <h1 className="p-4">SSD</h1>
    <div className="desk2 pb-5">
{ssd.map((values)=>
 <div class="card card1 border-0" style={{width:"500px",height:"300px",backgroundColor:"grey"}}>
 <img src= {values.ssdimg}className="p-4" alt="the image is not shown" style={{backgroundColor:"grey"}} height="250px" width="400px"/>
 <div class="card-body" style={{backgroundColor:"white"}}>
   <h6 class="card-title text-center">{values.ssdtit}</h6>
 </div>
</div>

)}
   


            
 

   
    </div>
    
  </div>
        </>
    )
}
export default Sata;