import React from "react"
import { ser1 } from "./Export"
function It(){
return(
    <>
     <div className="laptop mt-5 ms-5 ">
    <h1 className="cch">IT Infrastructure Services</h1>
    <div className="in">

       <div className="in2 in4">
       <div class="card border-0" >
     <img src={ser1}className="p-4 ccimg" alt="the image is not shown" />
     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title cct">We Provide Solutions to build IT Infrastructure for your office,warehouse,retail and all kind of business operations.We help client with right hardware,software and all  supporting IT assets so that they can run their business successfully</h6>
     </div>
   </div>
   </div>

 
  </div>
    </div>
    </>
)
}
export default It