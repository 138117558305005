import React from "react";
import { ser6 } from "./Export";
function Network(){
return(
    <>
<div className="laptop mt-5 ms-5 ">
    <h1 className="cch">Network Solutions</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0 d-flex" >
     <img src={ser6}className="p-4 ccimg" alt="the image is not shown"  />
     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title cct ">
        Network Infrastructure is most critical part of any IT Infrastructure.Whether it is head office with 1000+PCS or small branch office with fewe PCs,network planning management is most essential
       </h6>
     </div>
   </div>
   </div>

 
  </div>
    </div>
    </>
)
}
export default Network