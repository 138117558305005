import React from "react";
import { comp } from "./Data";
function Computer(){
    return(
        <>
      <div className="desk1 mt-5 ms-3">
    <h1 className="p-4 cch">Computer Peripheral</h1>
    <div className="desk2 pb-5 peri">
{comp.map((i)=>
    <div class="card card1 border-0" style={{backgroundColor:"grey"}}>
            <img src={i.compimg} className="p-4" alt="the image is not shown" style={{backgroundColor:"grey"}} />
            <div class="card-body carrr" style={{backgroundColor:"white"}}>
              <h6 class="card-title text-center cct">{i.comptitle}</h6>
            </div>
          </div>
  

)}
            
 

   
    </div>
    
  </div>
        </>
    )
}
export default Computer