import data from "./Data"

function Laptop(){
    return(
        <>
  <div className="laptop mt-5 ms-5 ">
    <h1 className="cch">Laptop</h1>
    <div className="laptop2">
    {data.map((items)=>
       <div className="laptop1">
       <img src={items.img} alt="" className="lapimg"/>
       <h6 className="text-center cct">{items.head}</h6>
   </div>

    )}
  </div>
    </div>
   
        </>
    )
}
export default Laptop