import React from "react";
import { ser3 } from "./Export";
function Tech(){
    return(
        <>
  <div className="laptop mt-5 ms-5 ">
    <h1 className="cch">Distribution Services</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0" >
     <img src={ser3}className="p-4 ccimg" alt="the image is not shown"  />
     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title cct">We are working multiple OEM and has successfully achieved required technical certifications and experience to deliver integration of multiple technologies as a business solutions.ISO standards are strictly followed in delivering seamless services.</h6>
     </div>
   </div>
   </div>

 
  </div>
    </div>
        </>
    )
}
export default Tech