import React from "react";
import "./Nav.css"
function Footer(){
    return(
        <>
     <div className="footer">
       <div className="footer1">
        <div className="footer2">
            <h2>GET CONNECTED WITH US</h2>
         <div className="icon11">
         <i class="fa-brands fa-facebook-f fa-beat"></i>
         <i class="fa-brands fa-linkedin-in fa-beat"></i>
            <i class="fa-brands fa-instagram fa-beat"></i>
            <i class="fa-brands fa-twitter fa-beat"></i>
            <i class="fa-solid fa-envelope fa-beat"></i>
         </div>
        </div>
        <hr className="m-5 hr" />
        <div className="footer3">
            <div className="company">
<h2> DECLUB SHOPPY PRIVATE LIMITED</h2>
            </div>
            <div className="products">
<h5>Products</h5>
<p>Laptop</p>
<p>Desktop</p>
<p>Interactive Panel</p>
<p>Accesories</p>
            </div>
            <div className="contact">
                <h5>Contact us</h5>
<p><i class="fa-solid fa-house"></i> 1st Floor,Plot no.25,Right Side Industrial Area Phase-1,Chandigarh 160002</p>
<p><i class="fa-solid fa-phone"></i> 9872601805</p>
<p>      <i class="fa-solid fa-envelope"></i>info@clubshoppy.com </p>
            </div>
        </div>
       </div>
     </div>
        </>
    )
}
export default Footer