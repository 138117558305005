import React from "react";
import { ser5 } from "./Export";
function Server(){

    return(
        <>
 <div className="laptop mt-5 ms-5 ">
    <h1 className="cch">Server Solutions</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0 d-flex">
     <img src={ser5}className="p-4 ccimg" alt="the image is not shown"  />
     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title cct">In Every day computing users are interacting with servers everywhere.They are an important element in the exchange of email,running key business applications,accessing data and browsing the web.As well as offering  a broad range of servers,we can also assit you in the selection and implementation of the right server for your needs.</h6>
     </div>
   </div>
   </div>

 
  </div>
    </div>
        </>
    )
}
export default Server