import React from "react"
import { ram } from "./Data"
function Ram(){
return(
    <>
 <div className="desk1 mt-5 ms-3">
    <h1 className="p-4">RAM</h1>
    <div className="desk2 pb-5">
{ram.map((i)=>
    <div class="card card1 border-0" style={{width:"600px",height:"300px",backgroundColor:"grey"}}>
            <img src={i.ramimg} className="p-4" alt="the image is not shown" style={{backgroundColor:"grey"}} height="200px" width="100%"/>
            <div class="card-body" style={{backgroundColor:"white"}}>
              <h6 class="card-title text-center">{i.ramtit}</h6>
            </div>
          </div>
  

)}
            
 

   
    </div>
    
  </div>
    </>
)
}
export default Ram