import React, { useState } from "react"
// import * as Yup from "yup"
import { Cont } from "./Export"
function Contact(){
    const [name,setName]=useState("")
    const[last,setLast]=useState("")
    const[email,setEmail]=useState("")
    const[num,setNum]=useState("")
   const[sub,setSub]=useState("")
  //  const validationSchema=Yup.object().shape({
  //   name:Yup.string().min("minimum 4 character").required("name is mandatory"),
  //   last:Yup.string().min("minimum 4 character").required("last name is manadatory"),
  //   email:Yup.string().required("email is mandatory"),
  //   num:Yup.number().max("10 digit").required("number is mandatory"),
  //   sub:Yup.string().min("10 words").required("enter the suject")
  //  })
const handle=(e)=>{
e.preventDefault()
}

return(
    <>
    <div  className="contact1">

<div className="contact2">
    {/* <h1 className="text-light ps-5 pe-4" style={{paddingTop:"10%"}}> Contact us </h1> */}
    <img src={Cont} alt="the image is not shown" height="500px" width="100%"/>
    <div className="conth">
      <h1 className="text-light ps-5 pe-4">Contact us</h1>
    </div>
</div>

<div className="contact3">
    <h2 className="text-center">Get In Touch</h2>
    
   
    <div className="contact4">
        <div className="contact5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1715.1197010125493!2d76.8075557!3d30.7116695!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fecdb55b01cff%3A0x43b8b36a1d00ebaf!2s25%2C%20Industrial%20Area%20Phase%20I%2C%20Chandigarh%2C%20160002!5e0!3m2!1sen!2sin!4v1726474720486!5m2!1sen!2sin" width="600" height="450" style={{border:"1",allowfullscreen:"" ,loading:"lazy" ,referrerpolicy:"no-referrer-when-downgrade"}}></iframe>
        </div>
        <div className="contact7">
            <form>
            <div className="formm">
            <div className="form1" >
            <label className="form-label">First Name</label>
            <input type="text" className="form-control" value={name} onChange={(e)=>setName(e.preventDefault())} required/>
        
          </div>
          <div className="form1" >
            <label className="form-label">Last Name</label>
            <input type="text" className="form-control" value={last} onChange={(e)=>setLast(e.preventDefault())} required/>
          </div>
          <div className="form1">
            <label className="form-label">Mobile No.</label>
            <input type="tel" className="form-control" value={num} onChange={(e)=>setNum(e.preventDefault())}required/>
          </div>
          <div className="form1">
            <label className="form-label">E-mail</label>
            <input type="email" className="form-control" value={email} onChange={(e)=>setEmail(e.preventDefault())} required/>
          </div>
          <div className="form1 subject">
            <label>Subject</label>
            <input type="text" className="form-control subj"  value={sub} onChange={(e)=>setSub(e.preventDefault())}/>
           
          </div>
        
          <div className="form1">
          <button type="submit" className="btn btn-outline-secondary  " onClick={handle}>Submit</button>
          </div>
            </div>
       
       
        </form>
      
    </div>
      
    </div>
    <h3 className="ms-4 mt-4">Contact Information</h3>
    <div className="contact6">
        <div className="contac">
        <h5> HeadQuarter</h5>
        <p> <i class="fa-solid fa-house pe-2"></i>Ist Floor,Plot No.25,Right Side Industrial Area Phase-I,Chandigarh 160002</p>
        </div>
     <div className="contac">
     <h5>E-mail</h5>
     <p>   <i class="fa-solid fa-envelope pe-2"></i>  info@declubshoppy.com</p>
     </div>
          <div className="contac">
          <h5>Phone</h5>
          <p> <i class="fa-solid fa-phone pe-2"></i>   9872601805</p>
          </div>
    <div className="contac">
    <h5>Website</h5>
    <p> <i class="fa-solid fa-globe pe-2"></i>www.clubshoppy.com</p>
    </div>
         
        </div>
</div>
        </div>
        </>
)
}
export default Contact