import React from "react";
import { ser7 } from "./Export";
function Home(){

    return(
        <>
       <div className="laptop mt-5 ms-5 ">
    <h1 className="cch">Home Automation</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0 d-flex" >
     <img src={ser7}className="p-4 ccimg" alt="the image is not shown"  />
     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title cct">Our goal is enable a comfortable and worry-life for everyone.We are commited products and services that align technology with everyday activites of the common people and aim to make the world a smarter place</h6>
     </div>
   </div>
   </div>

 
  </div>
    </div>
        </>
    )
}
export default Home