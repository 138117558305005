import React from "react";
import { ser4 } from "./Export";
function Cc(){
 return(
    <>
   <div className="laptop mt-5 ms-5 ">
    <h1 className="cch">CCTV & Biometric Solutions</h1>
    <div className="in">

       <div className="in2">
       <div class="card border-0" >
     <img src={ser4}className="p-4 ccimg" alt="the image is not shown"  />
     <div class="card-body" style={{backgroundColor:"white"}}>
       <h6 class="card-title cct">We have wealth of knowledge and experience in CCTV security system.We specialist in CCTV only providing well known  and well supported CCTV products and services . </h6>
     </div>
   </div>
   
   </div>
   <div className="in3 mt-5">
    <h3 className="cch"> Biometric Solutions</h3>
    <h6 className="cct">Nowdays Biometric is getting popular day-by-day.With the help of biometric authentications,the oragnizations are able to maintain the records of their employee with ease.Moreover another reason for biometric getting popular is that the biometric provides foolproof of authentication</h6>
   </div>
 
  </div>
    </div>
    </>
 )
}
export default Cc