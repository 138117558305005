import React from "react";
import { table1 } from "./Data";
function Tab(){
    return(
        <>
    <div className="desk1 mt-5 ms-3">
    <h1 className="p-4">Tablets</h1>
    <div className="desk2 pb-5">
{table1.map((i)=>
    <div class="card card1 border-0" style={{backgroundColor:"grey"}}>
            <img src={i.tabletimg} className="p-4" alt="the image is not shown" style={{backgroundColor:"grey"}} height="330px" width="100%"/>
            <div class="card-body" style={{backgroundColor:"white"}}>
              <h6 class="card-title text-center">{i.tabletti}</h6>
            </div>
          </div>
  

)}
            
 

   
    </div>
    
  </div>
        </>
    )
}
export default Tab