import {in1,in2,in4,comm1,comm2,comm3,comm4,Hpp,led1,led2,led3,Desk1,Desk2,Desk3,Desk4,Comp1,Comp2,keyboard,Comp3, Tab1, Tab2,Tab3, Tab4,d22,d23,ssd1,portable} from "./Export"

  const data=[

   {
    img:"https://www.electronicsbazaar.com/media/catalog/product/cache/42cb70b607a6ad026bed848f494879b1/q/c/qcnbag03106_1.jpg",
    head:" Lenovo All Series",
   
   },
   {
    img:"https://www.electronicsbazaar.com/media/catalog/product/cache/42cb70b607a6ad026bed848f494879b1/q/c/qcnbag00323_1.jpg",
    head:" Dell All Series"
   },
   {
    img:"https://www.electronicsbazaar.com/media/catalog/product/cache/fa77d75c1312b9ab17a971266464d579/q/c/qcnbag00865_1.webp",
    head:" Acer All Series"
   },
   {
    img:Hpp,
    head:" Hp All Series"
   }

]
 export const items=[{
   imgg:in1,
   head:" Xboard v7 series"
},
{
   imgg:in2,
   head:"V6 Transcend Series"
},
{
   imgg:in4,
   head:"Education Series"
},
]
export const com=[{
   comim:comm1,
   title:"UW Series"
},
{
   comim:comm2,
   title:"CMD Series"

},
{
   comim:comm3,
   title:"CMA Series"
},
{
   comim:comm4,
   title:"CM-T Series"
}
]
export const ledd=[
   {
      ledimg:led1,
      title:"Raptors Series V3"
   },
   {
      ledimg:led2,
      title:"Raptors"
   },
   {
      ledimg:led3,
      title:"GH31 Series"
   }
]
export const desk=[
   {
      deskim:Desk1,
      desktit:"Dell All Series"
   },
   {
      deskim:Desk2,
      desktit:"HP All Series"
   },
   {
      deskim:Desk3,
      desktit:"Lenovo All Series"

   },
   {
      deskim:Desk4,
      desktit:"Acer All Series"
   }
]
export const comp=[
   {
      compimg:Comp1,
      comptitle:"Printer"
   },
   {
      compimg:Comp2,
      comptitle:"Mouse"
   },
   {
   compimg:keyboard,
   comptitle:"keyboard"
   },
   {
      compimg:Comp3,
      comptitle:"speaker"
   },
 
]
 export const table1=[
   {
      tabletimg:Tab1,
      tabletti:"Lenovo All Series"
   },
   {
      tabletimg:Tab2,
      tabletti:"Acer All Series"

   },
   {
      tabletimg:Tab3,
      tabletti:"HP All Series"
   },
   {
      tabletimg:Tab4,
      tabletti:"Dell All Series"
   }
]
 export const ram=[
   {
      ramimg:d22,
      ramtit:"DDR4 All "
   },
   {
      ramimg:d23,
      ramtit:"DDR5 All"
   }
]
export const ssd=[
   {
      ssdimg:ssd1,
      ssdtit:"SSD GEN 3*4"
   },
   {
      ssdimg:ssd1,
      ssdtit:"SSD GEN 4*4"
   },
   {
      ssdimg:portable,
      ssdtit:"SSD USB2.0 GEN 2 Portable"
   },
   // {
   //    ssdimg:"https://www.ipactech.com/images/snapedit_1722240457382-removebg-preview.png"
   // },
   {
ssdimg:"https://www.ipactech.com/images/snapedit_1722401199175_V11-removebg-preview.png",
ssdtit:"SSD GEN 3.0*4"
   }
   
]
export const usb=[
   {
      usbimg:"https://www.ipactech.com/images/snapedit_1722232157776-removebg-preview.png",
      usbtit:"M Series 8GB~ 128GB"
   },
   {
      usbimg:"https://www.ipactech.com/images/snapedit_1722233392983-removebg-preview.png",
      usbtit:"E Series 8GB ~ 64 GB"
   },
   {
      usbimg:"https://www.ipactech.com/images/snapedit_1722235559924-removebg-preview.png",
      usbtit:"M Series 8GB~ 128GB"
   },
   {
      usbimg:"https://www.ipactech.com/images/snapedit_1722401199175_V9-removebg-preview.png",
      usbtit:"R002 8GB ~ 64GB"
   }

]
export default data