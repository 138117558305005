import React from "react";
import data, { desk } from "./Data";
function Desktop(){
return(
    <>
   <div className="desk1 mt-5 ms-4">
    <h1 className="p-4">Desktop PC</h1>
    <div className="desk2 pb-5">
        {desk.map((val)=>
            <div class="card card1 border-0" style={{backgroundColor:"grey"}}>
            <img src={val.deskim} className="p-4 deskimg" alt="the image is not shown" style={{backgroundColor:"grey"}} height="330px" width="350px"/>
            <div class="card-body" style={{backgroundColor:"white"}}>
              <h6 class="card-title text-center">{val.desktit}</h6>
            </div>
          </div>
        )}
 

   
    </div>
    
  </div>
    </>
)
}
export default Desktop