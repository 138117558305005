import React,{useState} from "react";
import "./Nav.css"
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import {NavLink} from "react-router-dom"
import H from "./H";
function Nav(){
  const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return(
   
 <div className="head ">
 

<div className="navbb">
<nav className="navbar ">
            <div className="navbar-container">
                <div className="logo me-5">CLUB SHOPPY</div>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                    <li><NavLink to="/">Home</NavLink></li>
                  

                    <li className="nav-item dropdown">
    <NavLink className="nav-link dropdown-toggle" to="/product" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Products
    </NavLink>
    <ul className="dropdown-menu bg-dark"  style={{marginLeft:"18%"}}>
        <li className="dropdown-submenu" >
            <NavLink className="dropdown-item dropdown-toggle" to="/product/memory" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Memory
            </NavLink>
            <ul className="dropdown-menu bg-dark">
                <li><NavLink className="dropdown-item" to="/product/ram">RAM</NavLink></li>
                <li><NavLink className="dropdown-item" to="/product/sata">SSD</NavLink></li>
                <li><NavLink className="dropdown-item" to="/product/usb">USB Flash Drive</NavLink></li>
            </ul>
        </li>
        <li><NavLink className="dropdown-item" to="/product/laptop">Laptop</NavLink></li>
        <li><NavLink className="dropdown-item" to="/product/desktop">Desktop PC</NavLink></li>
        <li><NavLink className="dropdown-item" to="/product/tablet">Tablets</NavLink></li>
        <li><NavLink className="dropdown-item" to="/product/interactive">Interactive Panel</NavLink></li>
        <li><NavLink className="dropdown-item" to="/product/commercial">Commercial Display</NavLink></li>
        <li><NavLink className="dropdown-item" to="/product/led">LED Display</NavLink></li>
        <li><NavLink className="dropdown-item" to="/product/computer">Computer Peripheral</NavLink></li>
        {/* <li><a className="dropdown-item" href="#">Computer Accesories</a></li> */}
      
    </ul>
</li>

                    <li><NavLink to="/about">About</NavLink>
                    </li>
                    <li className="nav-item dropdown">
    <NavLink className="nav-link dropdown-toggle" to="/services" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Services
    </NavLink>
    <ul className="dropdown-menu bg-dark"  style={{marginLeft:"18%"}}>
    
        <li><NavLink className="dropdown-item" to="/services/it">IT Infrastructure Services</NavLink></li>
        <li><NavLink className="dropdown-item" to="/services/dis">Distribution Services</NavLink></li>
        <li><NavLink className="dropdown-item" to="/services/tech">Technology Integration Services</NavLink></li>
        <li><NavLink className="dropdown-item" to="/services/cctv">CCTV & Biomatric Solutions</NavLink></li>
        <li><NavLink className="dropdown-item" to="/services/server">Server Solutions</NavLink></li>
        <li><NavLink className="dropdown-item" to="/services/network">Network Solutions</NavLink></li>
        <li><NavLink className="dropdown-item" to="/services/home">Home Automation</NavLink></li>
        <li><NavLink className="dropdown-item" to="/services/consumer">Consumer Electronics</NavLink></li>
        <li><NavLink className="dropdown-item"  to="/services/amc">AMC &FMC Solutions</NavLink></li>
        <li><NavLink className="dropdown-item" to="/services/sales">Computer Sales and Services</NavLink></li>
       
      
    </ul>
</li>

                    <li><NavLink to="/contact">Contact</NavLink></li>
                </ul>
            </div>
        </nav>
        
</div>
 <div className="input">
    <input type="text" className="form-control" placeholder=" What are you looking for"/>
 </div>
 <div className="icons ms-5">
 <i class="fa-solid fa-location-dot"><a href=""></a></i>
 <i class="fa-solid fa-user" ></i>
 <i class="fa-solid fa-cart-shopping" ></i>
 </div>
 </div>
   

    )
}
export default Nav