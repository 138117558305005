import React from "react"
import { com } from "./Data"
function Commercial(){

    return(
        <>
  <div className="comm">
    <h1 className="p-5 cch">Commercial Display</h1>
    <div className="comm1">
        <div className="comm2 m-4">
            {com.map((coms)=>
            <div class="card card1 border-0" style={{backgroundColor:"grey"}}>
            <img src={coms.comim} className="p-4" alt="the image is not shown" style={{backgroundColor:"grey"}} />
            <div class="card-body" style={{backgroundColor:"white"}}>
              <h6 class="card-title text-center cct">{coms.title}</h6>
              
            </div>
          </div>
            )}
        
        </div>
    </div>
  </div>
        </>
    )
}
export default Commercial